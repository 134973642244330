import './portfolio.css';
import port1 from '../../assets/portfolio-silabah.JPG';
import port2 from '../../assets/portfolio-geekspace.JPG';
import port3 from '../../assets/portfolio-ecommerce.JPG';

const Portfolio = () => {

  const data = [
    {
      id: 1,
      image: port1,
      title: 'Information System For Lab Equipment Rent',
      demoUrl: 'https://silabah.000webhostapp.com'
    },
    {
      id: 2,
      image: port2,
      title: 'CRUD Implementation From Movies & Games API',
      demoUrl: 'https://geekspaceid.netlify.app/'
    },
    {
      id: 3,
      image: port3,
      title: 'Mini E-commerce Build With Next Js',
      demoUrl: 'https://sanbercode-nextjs-batch-37-ilhamrdh21.vercel.app/'
    }
  ]

  return(
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map((item) => {
            return(
              <article className='portfolio__item' key={item.id}>
                <div className="portfolio__item-image">
                  <img src={item.image} alt={'Portfolio '+item.id} height='160px' />
                </div>
                <h3>{item.title}</h3>
                <div className="portfolio__item-cta">
                  <a href={item.demoUrl} className='btn btn-primary' target='__blank'>Live Demo</a>
                </div>
              </article>
            );
          })
        }
      </div>
    </section>

  )
}

export default Portfolio;