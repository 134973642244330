import {BsLinkedin, BsInstagram} from 'react-icons/bs';
import {IoLogoGitlab} from 'react-icons/io5';

const HeaderSocials = () => {
  return(
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/mohilhamrdh" target="__blank"><BsLinkedin /></a>
      <a href="https://gitlab.com/ilhamrdh21" target="__blank"><IoLogoGitlab /></a>
      <a href="https://www.instagram.com/ilhmridho/" target="__blank"><BsInstagram /></a>
    </div>
  )
}

export default HeaderSocials;