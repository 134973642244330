import './about.css';
import aboutImage from '../../assets/me-about.jpg';
import {FaAward} from 'react-icons/fa';
import {VscFolderLibrary} from 'react-icons/vsc';

const About = () => {
  return(
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={aboutImage} alt="about me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward  className='about__icon'/>
              <h5>Experience</h5>
              <small>4+ Months Working</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary  className='about__icon'/>
              <h5>Projects</h5>
              <small>3 Personal Projects</small>
            </article>
          </div>

          <p>
            I am a bachelor of information systems that recently graduated from the Faculty of Computer Science, University of Brawijaya. I have a personal interest in web development and business process management based on my study and experience. I am eager to learn from new experiences and obtain new insights. I am able to work as a team & as an individual. I am an organized, well-rounded, and easy-to-adapt person who has a desire to reach my goals. Now I am looking for great opportunities to implement what I have learned and I am ready for the next challenges.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's talk</a>
        </div>
      </div>
    </section>
  )
}

export default About;