import './nav.css';
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai'
import {MdWorkOutline} from 'react-icons/md'
import {BiMessageDetail, BiBook} from 'react-icons/bi'
import { useContext } from 'react';
import { NavContext } from '../../contexts/NavContext';

const Nav = () => {

  const {activeNav, setActiveNav} = useContext(NavContext);

  return(
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a href="#about" onClick={() => setActiveNav('about')} className={activeNav === 'about' ? 'active' : ''}>
        <AiOutlineUser />
      </a>
      <a href="#experience" onClick={() => setActiveNav('experience')} className={activeNav === 'experience' ? 'active' : ''}>
        <BiBook />
      </a>
      <a href="#portfolio" onClick={() => setActiveNav('portfolio')} className={activeNav === 'portfolio' ? 'active' : ''}>
        <MdWorkOutline />
      </a>
      <a href="#contact" onClick={() => setActiveNav('contact')} className={activeNav === 'contact' ? 'active' : ''}>
        <BiMessageDetail />
      </a>

    </nav>

  )
}

export default Nav;